import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from "gatsby"
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import FormControl from '@material-ui/core/FormControl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReCAPTCHA from 'react-google-recaptcha'

import Layout from '@components/layout/Layout'
import LeavingSiteModal from '@components/leaving-site-modal/leaving-site-modal.component'
import SiteLink from '@components/SiteLink'
import EmailFinderComponent from '@components/locate-sleep-specialist/email-finder/email-finder.component'
import stateList from '@components/states'
import { specialityList, professionalDesignationList } from '@components/profile/profile.util'
import CommonModal from '@components/common-modal/common-modal.component'
import { TextValidator, SelectValidator, CheckboxValidator, RadioValidator, ValidatorForm } from '@components/validators'
import { trackGa } from '@components/GoogleTracking'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import config from "@utils/config"

import './register-specialist-profile.scss'

const ReCAPTCHAKey = `${config.REACT_APP_GOOGLE_RECAPTCHA}`

class RegisterSpecialistProfile extends React.Component {
  constructor(props) {
    super(props)

    this.handleCloseSuccess = this.handleCloseSuccess.bind(this)
    this.handleCloseError = this.handleCloseError.bind(this)
    this.handleShowModalClicked = this.handleShowModalClicked.bind(this)
    this.handleHideModal = this.handleHideModal.bind(this)

    this.state = {
      First: '',
      Middle: '',
      Last: '',
      ProfessionalDesignation: '',
      Specialty: '',
      GroupPracticeName: '',
      WebSite: '',
      Address1: '',
      Address2: '',
      City: '',
      State: '',
      Country: 'Untited States',
      Zip: '',
      Phone: '',
      Fax: '',
      Email: '',
      StateLicense: '',
      StateLicenseExpirationDate: '',
      StateLicenseStateIssued: '',
      DEA: '',
      DEAExpirationDate: '',
      NpiNumber: '',
      PediatricSpecialist: '',
      NumDiagnosedPatients: '',
      isCertify: '',
      isAccept: '',
      loading: false,
      isYes: '',
      isNo: '',
      showSuccessModal: false,
      showErrorModal: false,
      errors: null,
      showLeavingSiteModal: false,
      href: '',
      canContinue: false,
    }

    this.onSubmitForm = this.onSubmitForm.bind(this)
  }

  allowContinue() {
    this.setState({ canContinue: true })
  }
  disableContinue() {
    this.setState({ canContinue: false })
  }

  handleInputChange(label, event) {
    let value = event.target ? event.target.value : event

    this.setState(
      {
        [label]: value,
      },
      () => {}
    )
  }

  handleCloseSuccess() {
    this.setState({
      showSuccessModal: false,
    })
    navigate(-1)
  }

  handleCloseError() {
    this.setState({
      showErrorModal: false,
    })
  }

  handleHideModal() {
    this.setState({
      showLeavingSiteModal: false,
    })
  }

  handleShowModalClicked() {
    this.setState({
      showLeavingSiteModal: true,
    })
  }

  openLink(link) {
    this.setState(() => {
      return { href: link }
    }, this.handleShowModalClicked())
  }

  getErrorsFormatted(errors) {
    if (errors) {
      if (typeof errors === 'string') {
        return <p className="errors">{errors}</p>
      } else {
        return (
          <ul className="errors">
            {Object.keys(errors).map((value, index) => {
              return <li key={index}>{errors[value]}</li>
            })}
          </ul>
        )
      }
    }
  }

  onSubmitForm(event) {
    trackGa('register,	click,	submit information - register')
    event.preventDefault()
    this.setState({ ...this.state, loading: true })
    let postData = { ...this.state }
    const address = this.state.Address1 + ', ' + this.state.City + ', ' + this.state.State + ', ' + this.state.Zip
    const google = window.google
    const geocoder = new google.maps.Geocoder()

    geocoder.geocode(
      {
        address: address,
      },
      (geoData, geoStatus) => {
        if (geoStatus == google.maps.GeocoderStatus.OK) {
          (postData.Lat = geoData[0].geometry.location.lat()), (postData.Lng = geoData[0].geometry.location.lng())
        }

        fetch(`${config.REACT_APP_API_HOST}/physician-finder.php/AddPhysician`, {
          method: 'POST',
          body: JSON.stringify(postData),
        })
          .then((res) => res.json())
          .then((data) => {
            if (!data.ErrorMessage || data.StatusCode === 'SUCCESS') {

              this.setState({
                ...this.state,
                loading: false,
                showSuccessModal: true,
              })
            } else {
              this.setState({
                showErrorModal: true,
                loading: false,
                errors: data.ErrorMessage || data.Description,
              })
            }
          })
      }
    )
  }

  render() {
    return (
      <Layout addedClass='page-register-find-sleep-specialist' location={this.props.location}>
        <div className="primary-container">
          <h1 className="main-page-header">Register to Be Included in the Sleep Specialist Directory</h1>
          <p className="page-comment">
            Healthcare professionals with an expertise in sleep disorders, including (but not limited to) neurologists,
            psychiatrists, pulmonologists, and sleep medicine specialists, are invited to submit their contact
            information for inclusion in the Sleep Specialist directory.
          </p>
          <h2 className="section-header">Create Your Profile</h2>
          <div className="form-comments">
            <p className="form-comment">
              You will be contacted once your information is verified and your listing has gone live in the directory.
              Your listing information can be updated or removed at any time on your request.
            </p>
            <p className="form-comment">
              The Sleep Specialist directory is accessible to the general public and prospective patients through
              NarcolepsyLink.com and MoreThanTired.com, both operated by Jazz Pharmaceuticals, Inc., as educational
              resources. These websites do not mention or link to information about any product or therapy.
            </p>
            <p className="form-comment">
              If you are already in the directory and wish to update or remove your listing,{' '}
              <SiteLink
                className="link-text"
                to="/stay-connected/update-sleep-specialist-directory/"
                tracking="register, click, remove listing - register"
              >
                click here
              </SiteLink>
              . If you are not a healthcare professional, please{' '}
              <span className="link-text" onClick={() => this.openLink('https://www.morethantired.com')}>
                click here
              </span>
              .
            </p>
          </div>
        </div>
        <div className="primary-container">
          <ValidatorForm onSubmit={this.onSubmitForm} instantValidate={false}>
            <div className="form-row">
              <div className="input-group">
                <TextValidator
                  name="First"
                  className="input-normal"
                  label="First Name*"
                  value={this.state.First}
                  onChange={(val) => this.handleInputChange('First', val)}
                  validators={['required', 'isName']}
                  errorMessages={['Please fill out this field', 'No numbers or special characters in this field']}
                />
                <TextValidator
                  name="Middle"
                  className="input-medium"
                  label="Middle Initial"
                  value={this.state.Middle}
                  onChange={(val) => this.handleInputChange('Middle', val)}
                  validators={['isName']}
                  errorMessages={['No numbers or special characters in this field']}
                />
              </div>
              <div className="input-group">
                <TextValidator
                  name="Last"
                  className="input-normal"
                  label="Last Name*"
                  value={this.state.Last}
                  onChange={(val) => this.handleInputChange('Last', val)}
                  validators={['required', 'isName']}
                  errorMessages={['Please fill out this field', 'No numbers or special characters in this field']}
                />
                <div>
                  <SelectValidator
                    name="ProfessionalDesignation"
                    className="input-normal-plus"
                    value={this.state.ProfessionalDesignation}
                    onChange={(e) => this.handleInputChange('ProfessionalDesignation', e.target.value)}
                    label="Professional Designation*"
                    validators={['required']}
                    errorMessages={['Please fill out this field']}
                  >
                    <option value="" disabled>
                      Professional Designation*
                    </option>
                    {professionalDesignationList.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </SelectValidator>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="input-group">
                <div className=" input-normal">
                  <SelectValidator
                    name="Specialty"
                    className="input-normal"
                    value={this.state.Specialty}
                    onChange={(e) => this.handleInputChange('Specialty', e.target.value)}
                    label="Specialty*"
                    validators={['required']}
                    errorMessages={['Please fill out this field']}
                  >
                    <option value="" disabled>
                      Specialty*
                    </option>
                    {specialityList.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </SelectValidator>
                </div>
                <TextValidator
                  name="GroupPracticeName"
                  className="input-normal"
                  label="Name of Practice"
                  value={this.state.GroupPracticeName}
                  onChange={(val) => this.handleInputChange('GroupPracticeName', val)}
                  validators={['isName']}
                  errorMessages={['No numbers or special characters in this field']}
                  autoComplete="disabled"
                />
              </div>
              <TextValidator
                name="WebSite"
                className="input-normal-plus"
                label="Physician/Practice Website"
                value={this.state.WebSite}
                onChange={(val) => this.handleInputChange('WebSite', val)}
                validators={['isWebsite']}
                errorMessages={['Please enter a valid web address']}
              />
            </div>
            <div className="form-row">
              <div className="input-group">
                <TextValidator
                  name="Address1"
                  className="input-normal"
                  label="Address 1*"
                  value={this.state.Address1}
                  onChange={(val) => this.handleInputChange('Address1', val)}
                  validators={['required']}
                  errorMessages={['Please fill out this field']}
                />
                <TextValidator
                  name="Address2"
                  className="input-normal"
                  label="Address 2"
                  value={this.state.Address2}
                  onChange={(val) => this.handleInputChange('Address2', val)}
                />
              </div>
              <div className="input-group">
                <TextValidator
                  name="City"
                  className="input-normal"
                  label="City*"
                  value={this.state.City}
                  onChange={(val) => this.handleInputChange('City', val)}
                  validators={['required', 'isAlphanumeric']}
                  errorMessages={['Please fill out this field', 'Entry is not valid']}
                />
                <SelectValidator
                  name="State"
                  className="input-small"
                  value={this.state.State}
                  onChange={(e) => this.handleInputChange('State', e.target.value)}
                  validators={['required']}
                  errorMessages={['Please fill out this field']}
                  label="State*"
                >
                  <option value="" disabled>
                    State*
                  </option>
                  {stateList.map((item) => (
                    <option key={item.abbreviation} value={item.abbreviation}>
                      {item.abbreviation}
                    </option>
                  ))}
                </SelectValidator>
                <TextValidator
                  name="Zip"
                  className="input-small-medium"
                  label="ZIP*"
                  value={this.state.Zip}
                  onChange={(val) => this.handleInputChange('Zip', val)}
                  validators={['required', 'isZip']}
                  errorMessages={['Please fill out this field', 'ZIP code is not valid']}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="input-group">
                <TextValidator
                  name="Phone"
                  className="input-normal"
                  label="Office Phone*"
                  value={this.state.Phone}
                  onChange={(val) => this.handleInputChange('Phone', val)}
                  validators={['required', 'isPhoneNumber']}
                  errorMessages={['Please fill out this field', 'Phone number is invalid']}
                />
                <TextValidator
                  name="Fax"
                  className="input-normal"
                  label="FAX"
                  value={this.state.Fax}
                  onChange={(val) => this.handleInputChange('Fax', val)}
                  validators={['isPhoneNumber']}
                  errorMessages={['Phone number is invalid']}
                />
              </div>
              <TextValidator
                name="Email"
                type="email"
                className="input-large"
                label="Email*"
                onChange={(val) => this.handleInputChange('Email', val)}
                value={this.state.Email}
                validators={['required', 'isEmail']}
                errorMessages={['Please fill out this field', 'Email is not valid']}
              />
            </div>
            <p className="form-header">Confirm Your Identity</p>
            <p className="item-comment">
              In order to update your profile in the Sleep Specialist directory, you must confirm your identity by
              entering your State License and DEA numbers. (This information is private and will not be shared with
              anyone, including website users.)
            </p>
            <div className="form-row confirm-identity">
              <div className="input-group">
                <TextValidator
                  name="StateLicense"
                  className="input-normal"
                  label="State License Number*"
                  value={this.state.StateLicense}
                  onChange={(val) => this.handleInputChange('StateLicense', val)}
                  validators={['required', 'isNumbers']}
                  errorMessages={['Please fill out this field', 'Only numbers allowed']}
                  autoComplete="disabled"
                />
                <TextValidator
                  name="StateLicenseExpirationDate"
                  className="input-normal"
                  label="License Expiration Date*"
                  placeholder="MM/DD/YYYY"
                  value={this.state.StateLicenseExpirationDate}
                  onChange={(val) => this.handleInputChange('StateLicenseExpirationDate', val)}
                  validators={['required', 'isDate', 'isValidDate']}
                  errorMessages={[
                    'Please fill out this field',
                    'Please enter a valid date',
                    'Please enter a valid date',
                  ]}
                  autoComplete="disabled"
                />
              </div>
              <div className=" input-medium">
                <SelectValidator
                  name="StateLicenseStateIssued"
                  className="input-medium"
                  value={this.state.StateLicenseStateIssued}
                  onChange={(val) => this.handleInputChange('StateLicenseStateIssued', val)}
                  label="State Issued*"
                  validators={['required']}
                  errorMessages={['Please fill out this field']}
                  autoComplete="disabled"
                >
                  <option value="" disabled>
                    State Issued*
                  </option>
                  {stateList.map((item) => (
                    <option key={item.abbreviation} value={item.abbreviation}>
                      {item.name}
                    </option>
                  ))}
                </SelectValidator>
              </div>
            </div>
            <div className="form-row">
              <div className="input-group">
                <TextValidator
                  name="DEA"
                  className="input-normal"
                  label="DEA Number*"
                  value={this.state.DEA}
                  onChange={(val) => this.handleInputChange('DEA', val)}
                  validators={['required', 'isDea']}
                  errorMessages={['Please fill out this field', 'Please enter a valid DEA number']}
                />
                <TextValidator
                  name="DEAExpirationDate"
                  className="input-normal"
                  label="DEA Expiration Date*"
                  value={this.state.DEAExpirationDate}
                  pattern="((0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/([1]\d{3}|20\d{2}))"
                  placeholder="MM/DD/YYYY"
                  onChange={(val) => this.handleInputChange('DEAExpirationDate', val)}
                  validators={['required', 'isDate', 'isValidDate']}
                  errorMessages={[
                    'Please fill out this field',
                    'Please enter a valid date',
                    'Please enter a valid date',
                  ]}
                />
              </div>
            </div>
            <div className="form-row">
              <TextValidator
                name="NpiNumber"
                className="input-normal"
                label="NPI Number"
                value={this.state.NpiNumber}
                onChange={(val) => this.handleInputChange('NpiNumber', val)}
                validators={['isNumbers']}
                errorMessages={['Only numbers allowed']}
                autoComplete="disabled"
              />
            </div>
            <div className="option-group">
              <label className="item-comment-label">Do you treat pediatric patients?*</label>
              <FormControl>
                <RadioValidator
                  name="PediatricSpecialist"
                  value={this.state.PediatricSpecialist}
                  validators={['required']}
                  errorMessages={['Please fill out this field']}
                  onClick={(e) => this.handleInputChange('PediatricSpecialist', e)}
                >
                  <FormControlLabel
                    className="radio-input"
                    value={'true'}
                    control={<Radio color="default" checked={this.state.PediatricSpecialist === 'true'} />}
                    label={<label>Yes</label>}
                  />
                  <FormControlLabel
                    className="radio-input"
                    value={'false'}
                    control={<Radio color="default" checked={this.state.PediatricSpecialist === 'false'} />}
                    label={<label>No</label>}
                  />
                </RadioValidator>
              </FormControl>
            </div>
            <div className="form-row last-count">
              <p className="item-comment-label">
                How many patients have you diagnosed with narcolepsy in the past 24 months?*
              </p>
              <TextValidator
                name="NumDiagnosedPatients"
                label=""
                className="input-number"
                value={this.state.NumDiagnosedPatients}
                onChange={(val) => this.handleInputChange('NumDiagnosedPatients', val)}
                validators={['required', 'isPositiveInt']}
                errorMessages={['Please fill out this field', 'Only numbers allowed']}
              />
            </div>
            <div className="form-row checkbox-group">
              <FormControlLabel
                control={
                  <CheckboxValidator
                    name="certify"
                    validators={['required', 'isTrue']}
                    errorMessages={['Please check this box if you want to proceed', 'Please check this box if you want to proceed']}
                    value={this.state.isCertify}
                    checked={this.state.isCertify}
                    onChange={(e) => this.handleInputChange('isCertify', e.target.checked)}
                  />
                }
                label="I certify that I have been trained to diagnose and differentiate sleep disorders."
              />

              <FormControlLabel
                control={
                  <CheckboxValidator
                    name="accept"
                    validators={['required', 'isTrue']}
                    errorMessages={['Please check this box if you want to proceed', 'Please check this box if you want to proceed']}
                    value={this.state.isAccept}
                    checked={this.state.isAccept}
                    onChange={(e) => this.handleInputChange('isAccept', e.target.checked)}
                  />
                }
                label={
                  <div>
                    I accept the{' '}
                    <span
                      className="checkbox-item privacy-statement-link"
                      onClick={() => this.openLink('https://www.jazzpharma.com/privacy-statement/')}
                    >
                      Privacy Policy and Terms of Use
                    </span>
                    *
                  </div>
                }
              />
            </div>
            <div className="form-row">
              <ReCAPTCHA
                className="goog-recaptcha"
                sitekey={ReCAPTCHAKey}
                onChange={() => this.allowContinue()}
                onExpired={() => this.disableContinue()}
                onErrored={() => this.disableContinue()}
                required
              />
            </div>
            <div className="form-row button-group">
              <button
                type="submit"
                disabled={!this.state.canContinue}
                className="action-button primary-button"

              >
                {this.state.loading && <FontAwesomeIcon className="loading" icon={faSpinner} spin />}SUBMIT
                INFORMATION&nbsp; <FontAwesomeIcon className="arrow-right" icon="angle-right" />
              </button>
              <SiteLink to="/stay-connected/locate-a-sleep-specialist/" className="secondary-button">
                <button className="action-button">CANCEL</button>
              </SiteLink>
            </div>
          </ValidatorForm>
        </div>
        <EmailFinderComponent />
        <CommonModal isShowModal={this.state.showSuccessModal}>
          <div className="body-content">
            <h2 className="title">
              Thank you for <br /> creating a profile with <br /> NarcolepsyLink.
            </h2>
            <p className="content success-modal-content-text">
              You will be contacted once your <br /> information is verified and your listing <br /> has gone live in
              the directory.
            </p>
            <div className="action">
              <button className="button btn-primary" onClick={this.handleCloseSuccess}>
                Ok
              </button>
            </div>
          </div>
        </CommonModal>
        <CommonModal isShowModal={this.state.showErrorModal}>
          <div className="body-content">
            <h2 className="title">Error</h2>
            <p className="content">A server error occurred while submitting your request.</p>
            {this.getErrorsFormatted(this.state.errors)}
            <div className="action">
              <button className="button btn-primary" onClick={this.handleCloseError}>
                Ok
              </button>
            </div>
          </div>
        </CommonModal>
        <LeavingSiteModal
          isShowModal={this.state.showLeavingSiteModal}
          onClose={this.handleHideModal}
          href={this.state.href}
        />
      </Layout>
    )
  }
}

RegisterSpecialistProfile.propTypes = {
  history: PropTypes.object,
}

export default RegisterSpecialistProfile
